import { postTgTrans } from "@/api";
export default {
  data() {
    return {
      chosePayItem: 0,
      showPicker: true,
      choseBank: ""
    };
  },
  methods: {
    chosePay(value) {
      this.chosePayItem = value;
    },
    onConfirm() {
      //   if (!this.money) {
      //     this.$toast("请输入金额")
      //     return
      //   }
      // if (this.userInfo.give_on == 0) {
      //   this.$toast("您已划转，不能重复划转")
      //   return
      // }

      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      postTgTrans().then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$store.dispatch("getUserInfo");
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};